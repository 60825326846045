<template>
  <PaginatedTable
    v-if="items"
    :fields="fields"
    :pageable="pageable"
    :page="page"
    @change="pageableChanged"
  ></PaginatedTable>
</template>

<script>
import PaginatedTable from "../PaginatedTable.vue";
import UtilsService, { SortDirection } from "../../services/utils.service";
export default {
  name: "StatsPageComponent",
  components: { PaginatedTable },
  props: ["items"],
  data: () => ({
    fields: [
      {
        key: "route",
        label: "Page",
        sortable: true,
        sortFunction: UtilsService.sortByStringProperty,
      },
      {
        key: "consultations",
        label: "Consultations",
        sortable: true,
        sortFunction: UtilsService.sortByNumberProperty,
      },
      {
        key: "utilisateurs",
        label: "Utilisateurs",
        sortable: true,
        sortFunction: UtilsService.sortByNumberProperty,
      },
    ],
    pageable: {
      currentPage: 1,
      perPage: 10,
      sortBy: "route",
      sortDesc: false,
    },
  }),
  computed: {
    page() {
      let sortProperty = this.fields.find(
        (f) => f.key === this.pageable.sortBy
      );
      let sortDirection = this.pageable.sortDesc
        ? SortDirection.DESC
        : SortDirection.ASC;
      return UtilsService.readOnlyPage(
        this.items,
        this.pageable.currentPage - 1,
        this.pageable.perPage,
        sortProperty.sortFunction.call(
          UtilsService,
          sortProperty.key,
          sortDirection
        )
      );
    },
  },
  methods: {
    pageableChanged(pageable) {
      this.pageable = pageable;
    },
  },
};
</script>
