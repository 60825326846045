<template>
  <section>
    <TitleBar tabsParent="backoffice">
      Backoffice
      <template #toolbar-content>
        <b-button v-b-toggle.filtres pill variant="primary">
          <b-icon-funnel></b-icon-funnel>
          Filtres
        </b-button>
      </template>
    </TitleBar>
    <b-container fluid>
      <b-collapse id="filtres">
        <b-row>
          <b-col>
            <SelectGroupeStatsComponent v-model="filter.agregat">
            </SelectGroupeStatsComponent>
          </b-col>
          <b-col>
            <b-form-group
              v-if="filter.agregat === 'PAGE'"
              id="group-email"
              label="Email utilisateur"
              label-for="input-email"
            >
              <b-form-input
                id="input-email"
                name="input-email"
                type="email"
                v-model="filter.email"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="group-date-debut"
              label="Entre le"
              label-for="input-date-debut"
            >
              <b-input
                id="input-date-debut"
                name="input-date-debut"
                type="date"
                v-model="filter.dateDebut"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="group-date-fin"
              label="Et le"
              label-for="input-date-fin"
            >
              <b-input
                id="input-date-fin"
                name="input-date-fin"
                type="date"
                v-model="filter.dateFin"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              block
              pill
              variant="outline-primary"
              @click="reinitialiser"
            >
              R&eacute;initialiser
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="load">
              Rechercher
            </b-button>
          </b-col>
        </b-row>
      </b-collapse>
      <section class="mt-3">
        <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
        <template v-if="result.agregat === 'PAGE'">
          <StatsPageComponent :items="result.items"></StatsPageComponent>
        </template>
        <template v-else-if="result.agregat">
          <StatsUtilisateurComponent
            :agregat="result.agregat"
            :items="result.items"
          ></StatsUtilisateurComponent>
        </template>
      </section>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import SelectGroupeStatsComponent from "../../components/inputs/SelectGroupeStatsComponent.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StatsUtilisateurComponent from "../../components/backoffice/StatsUtilisateurComponent.vue";
import StatsPageComponent from "../../components/backoffice/StatsPageComponent.vue";
import BackofficeService from "../../services/backoffice.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "StatistiquesView",
  components: {
    TitleBar,
    SelectGroupeStatsComponent,
    ErrorComponent,
    StatsUtilisateurComponent,
    StatsPageComponent,
  },
  data: () => ({
    filter: {
      agregat: null,
      email: null,
      dateDebut: null,
      dateFin: null,
    },
    error: null,
    result: {
      agregat: null,
      items: [],
    },
  }),
  mounted() {
    this.reinitialiser();
  },
  methods: {
    reinitialiser() {
      this.filter.agregat = "UTILISATEUR";
      this.filter.email = null;
      let date = new Date();
      this.filter.dateFin =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);
      date.setDate(date.getDate() - 30);
      this.filter.dateDebut =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);
      this.load();
    },
    load() {
      let agregat = this.filter.agregat;
      this.result.agregat = null;
      this.result.items = [];
      return (
        agregat === "PAGE"
          ? BackofficeService.getStatsNavigationParPage(
              this.filter.dateDebut,
              this.filter.dateFin,
              this.filter.email
            )
          : BackofficeService.getStatsUtilisateur(
              agregat,
              this.filter.dateDebut,
              this.filter.dateFin
            )
      )
        .then((response) => {
          this.result.agregat = agregat;
          this.result.items = response.data;
        })
        .catch((error) => {
          this.error = UtilsService.handleError(error);
        });
    },
  },
};
</script>
