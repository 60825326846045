<template>
  <b-form-group :id="`group-${id}`" :label="label" :label-for="`select-${id}`">
    <b-form-select
      v-if="options"
      :id="`select-${id}`"
      :name="`select-${id}`"
      :value="value"
      :options="options"
      @input="onInput"
      @change="onChange"
    ></b-form-select>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "SelectGroupeStatsComponent",
  props: ["value"],
  data() {
    return {
      id: `agregat-${uuidv4()}`,
      label: "Groupement",
      options: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.options = [
        { value: "UTILISATEUR", text: "Utilisateur" },
        { value: "GROUPE", text: "Groupe" },
        { value: "TYPE-UTILISATEUR", text: "Type d'utilisateur" },
        { value: "PAGE", text: "Page" },
      ];
    },
    onInput($event) {
      return this.$emit("input", $event);
    },
    onChange($event) {
      return this.$emit("change", $event);
    },
  },
};
</script>

<style scoped></style>
